#Contact {
  background-image: url("../Images/contact_cover.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position-y: 50%;
  position: relative;
}

.form {
  direction: rtl;
  line-height: 30px;
}

.layout {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(3, 0, 26, 0.92) 0%,
    rgba(82, 84, 207, 0.65) 100%
  );
}

.form_label {
  height: 30px;
  font-family: "Almarai";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;

  letter-spacing: 0.05em;
  color: #ffffff;
}

.form_input {
  height: 60px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
}

.form_input_textarea {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
}

.contact_header {
  position: relative;
  padding-top: 20px;
  font-family: "Almarai";
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 75px;
  text-align: center;
  color: #ffffff;
}

.contact_sub_header {
  position: relative;
  height: 45px;
  font-family: "Almarai";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 30px;
}

.submmit {
  width: 210px;
  border-radius: 8px;
  margin-top: 20px;
}

.send {
  width: 62px;
  height: 45px;
  font-family: "Almarai";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 45px;
  text-align: center;
  color: #5254cf;
}

.col_header {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.list_span {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .Form_test {
    margin-bottom: 30px;
  }

  .ifram {
    height: 200px;
  }
}

.Form_test {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 560px) {
  .contact_header {
    font-weight: 700;
    font-size: 30px;
  }

  .contact_sub_header {
    font-weight: 600;
    font-size: 14px;
    line-height: 45px;
  }

  .send {
    font-weight: 700;
    font-size: 20px;
    line-height: 35px;
  }
  .submmit {
    font-size: 15px;
    font-weight: 500;
    width: 150px;
  }
}
