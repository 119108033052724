.custcontmsg {
  width: 100%;
  background: rgba(82, 84, 207, 0.06);
}

.msghdr,
.msgpargrph {
  font-family: "Almarai";
  font-style: normal;
  display: flex;
  flex-direction: row-reverse;
}

.colpad {
  margin-right: 64px;
}
.ordd2 {
  margin-top: 50px;
}
.msghdr {
  font-weight: 700;
  font-size: 40px;
  color: #002cfd;
}

.msgpargrph {
  font-weight: 500;
  font-size: 22px;
  text-align: right;
  color: #1b1b43;
}

.custimgmsg {
  width: 158%;
  height: 100%;
}

.imgovflwmsg {
  overflow-x: hidden;
}

@media (min-width: 1092px) {
  .custcontmsg,
  .custcontmsg-lg,
  .custcontmsg-md,
  .custcontmsg-sm,
  .custcontmsg-xl {
    max-width: 1352px;
  }
}

@media (max-width: 980px) {
  .ordd {
    display: flex;
    order: 3;
  }

  .ordd2 {
    display: contents;
  }
  .colpad {
    margin: 0;
  }
  .msghdr {
    font-weight: 700;
    font-size: 35px;
  }
  .msgpargrph {
    font-weight: 500;
    font-size: 22px;
  }
}

@media (min-width: 280px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 100%;
  }

  .colpad {
    padding: 10px;
  }
  .msghdr {
    font-weight: 700;
    font-size: 25px;
    line-height: 50px;
  }
  .msgpargrph {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
  }
}

@media (min-width: 280px) {
  .msgpargrph {
    font-size: 22px;
  }
}

@media (max-width: 700px) {
  .msgpargrph {
    font-size: 16px;
  }

  .msghdr {
    padding-top: 20px;
  }
}
