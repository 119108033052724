.my-carousel-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transform: translateY(-90px);
  margin: 0 40px;
}

.my-carousel-card {
  max-width: 396px;
  min-height: 190px;
  background: #ffffff;
  box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 30px 41px;
}

.first-heading {
  height: 30px;
  font-family: "Almarai";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #1b1b43;
}

.first-paragraph {
  height: 80px;
  font-family: "Almarai";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: #1b1b43;
}

@media (max-width: 1300px) {
  .my-carousel-card {
    max-width: 350px;
  }
}

@media (max-width: 1200px) {
  .my-carousel-card {
    max-width: 310px;
    height: 240px;
  }
  .special-heading {
    margin-bottom: 30px;
  }
}

@media (max-width: 1100px) {
  .my-carousel-card {
    max-width: 260px;
    height: 260px;
  }
}

@media (max-width: 900px) {
  .my-carousel-card {
    max-width: 230px;
    height: 290px;
  }
  .special-heading1 {
    margin-bottom: 30px;
  }
}

/*max min 870 */

@media (max-width: 870px) {
  .my-carousel-container {
    transform: translateY(-100px);
    overflow-y: hidden;
    overflow-x: scroll;
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
  }
  .my-carousel-container::-webkit-scrollbar {
    display: none;
  }

  .my-carousel-card {
    min-width: 220px;
    min-height: 190px;
    background: #ffffff;
    box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 30px 41px;
    margin-right: 20px;
  }
  .special-heading2 {
    margin-bottom: 30px;
  }
}

@media (max-width: 789px) {
  .my-carousel-container {
    transform: translateY(-115px);
  }
}

@media (max-width: 400px) {
  .my-carousel-container {
    transform: translateY(-125px); /*edited with HomeEdited*/
  }
}

@media (max-width: 300px) {
  .my-carousel-card {
    min-width: 200px;
    height: 190px;
    background: #ffffff;
    box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 30px 41px;
    margin-right: 20px;
  }
}

@media (max-width: 280px) {
  .my-carousel-card {
    min-width: 190px;
    min-height: 260px;
    background: #ffffff;
    box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 20px 20px;
    margin-right: 20px;
  }
}
