a {
  list-style: none;
  text-decoration: none;
  color: #736d6f;
  font-size: 16px;
  font-weight: 600;
}

a:hover {
  color: rgba(0, 0, 0, 0.671);
}

.open {
  display: none;
}

.navbarr {
  position: relative;
  height: 100px;
  width: 100%;
  background-color: white;
}

.image {
  width: 125px;
      height: 70px;
  background: url("../icons/NewLogo.png");
}
.img{
  width: 125px;
  height: 70px;
}
.navbar-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 0 19px;
}

.links {
  margin-right: 70px;
  width: 547px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.links-drop-down {
  display: none;
}

/*media query*/

@media (max-width: 990px) {
  .navbar-content {
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .links {
    display: none;
  }
  .open {
    display: flex;
    position: absolute;
    font-size: 30px;
    background-color: white;
    top: 30px;
    left: 15px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 5px 12px;
    border-radius: 6px;
    border: 1px solid #8080806b;
  }

  .links-drop-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0;
    height: 400px;
  }
}
