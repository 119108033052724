.home-main {
  animation: change 10s infinite;
  height: 110vh;
}

@keyframes change {
  0% {
    background: linear-gradient(
        0deg,
        rgba(3, 0, 26, 1),
        rgba(5, 0, 37, 0.6569),
        rgba(7, 0, 58, 0)
      ),
      url("../Images/HomeBackground.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
  }
  33% {
    background: linear-gradient(
        0deg,
        rgba(3, 0, 26, 1),
        rgba(5, 0, 37, 0.6569),
        rgba(7, 0, 58, 0)
      ),
      url("../Images/Cover\ 4.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
  }
  66% {
    background: linear-gradient(
        0deg,
        #03001a 0%,
        rgba(5, 0, 37, 0.656928) 48.33%,
        rgba(7, 0, 58, 0) 100%
      ),
      url("../Images/HomeBackground5.jpeg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
  }
  100% {
    background: linear-gradient(
        0deg,
        rgba(3, 0, 26, 1),
        rgba(5, 0, 37, 0.6569),
        rgba(7, 0, 58, 0)
      ),
      url("../Images/HomeBackground.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
  }
}
/* 
.home-main2 {
  background: linear-gradient(
      0deg,
      #03001a 0%,
      rgba(5, 0, 37, 0.656928) 48.33%,
      rgba(7, 0, 58, 0) 100%
    ),
    url("../Images/HomeBackground3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 110vh;
}

.home-main3 {
  background: linear-gradient(
      0deg,
      rgba(3, 0, 26, 1),
      rgba(5, 0, 37, 0.6569),
      rgba(7, 0, 58, 0)
    ),
    url("../Images/HomeBackground2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 110vh;
} */

.home-content-container {
  display: flex;
  width: 100%;
  height: 85%;
  align-items: center;
  justify-content: space-between;
  padding: 0 70px;
}

.home-logo-img {
  width: 250px;
}

.home-text {
  width: 600px;
  text-align: center;
}

.first-paragraph-home,
.second-paragraph-home,
.third-paragraph-home {
  font-family: "Almarai";
  font-style: normal;
  color: #ffffff;
}
.first-paragraph-home,
.second-paragraph-home {
  font-weight: 700;
  font-size: 22px;
  line-height: 48px;
  letter-spacing: 1;
}

.second-paragraph-home {
  color: rgba(0, 163, 255, 1);
}

.third-paragraph-home {
  font-weight: 500;
  font-size: 17px;
  line-height: 40px;
  letter-spacing: 1;
}

/*media queries*/

@media (max-width: 319px) {
  .home-main,
  .home-main2,
  .home-main3 {
    height: 823px;
  }
  .home-logo-img {
    width: 160px;
    padding-top: 90px;
  }

  .home-content-container {
    justify-content: space-between;
    flex-direction: column;
    gap: 40px;
    padding: 0 70px;
  }

  .first-paragraph-home,
  .second-paragraph-home {
    font-size: 15px;
    line-height: 36px;
  }

  .third-paragraph-home {
    font-size: 14px;
    line-height: 28px;
  }

  .home-text {
    width: 260px;
    text-align: center;
    padding: 0 10px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .home-main,
  .home-main2,
  .home-main3 {
    height: 800px;
  }
  .home-logo-img {
    width: 160px;
    padding-top: 90px;
  }

  .home-content-container {
    justify-content: space-between;
    flex-direction: column;
    gap: 40px;
    padding: 0 70px;
  }

  .first-paragraph-home,
  .second-paragraph-home {
    font-size: 17px;
    line-height: 36px;
  }

  .third-paragraph-home {
    font-size: 14px;
    line-height: 28px;
  }

  .home-text {
    width: 300px;
    text-align: center;
  }
}

/*height query*/

@media (min-width: 481px) and (max-width: 768px) {
  .home-main,
  .home-main2,
  .home-main3 {
    height: 751px;
  }
  .home-logo-img {
    width: 190px;
    padding-top: 90px;
  }

  .home-content-container {
    justify-content: space-between;
    flex-direction: column;
    gap: 40px;
    padding: 0 70px;
  }

  .first-paragraph-home,
  .second-paragraph-home {
    font-size: 18px;
    line-height: 40px;
  }

  .third-paragraph-home {
    font-size: 14px;
    line-height: 35px;
  }

  .home-text {
    width: 566px;
    text-align: center;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .home-text {
    width: 450px;
    text-align: center;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .home-logo-img {
    width: 200px;
  }
  .first-paragraph-home,
  .second-paragraph-home {
    font-size: 20px;
    line-height: 40px;
  }

  .third-paragraph-home {
    font-size: 15px;
    line-height: 35px;
  }

  .home-text {
    width: 700px;
    text-align: center;
  }

  .home-content-container {
    gap: 65px;
    padding: 0 30px;
  }
  .home-main,
  .home-main2,
  .home-main3 {
    height: 500px;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
}

.carousel-indicators [data-bs-target] {
  width: 0px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 0px;
}
