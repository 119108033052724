.footer_header {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  color: #000000;
  padding-top: 12px;
  padding-bottom: 8px;
}

.footer_sub_header {
  max-width: 367px;
  direction: rtl;
  font-family: "Almarai";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 240px;
  text-align: center;
  line-height: 1.4;
  color: #000000;
}

.footer_header2 {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  margin-top: 10px;
  text-align: center;
  letter-spacing: 0.05em;

  color: #000000;
}

.col1 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 880px) {
  .footer_header {
    font-size: 22px;
  }
  .footer_header2 {
    font-size: 18px;
  }
}

.iconlist {
  list-style: none;
}

.icon_item {
  display: inline-block;
}

.footer_icon {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-decoration: none;
  text-align: center;
  color: #000000;
}

.icon_render {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.icon_render:hover {
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-10px);

  cursor: pointer;
}

.footer_lis {
  list-style: none;
  direction: rtl;
}
.fotter_style {
  justify-content: flex-end;
  flex-wrap: nowrap;
}

@media (max-width: 588px) {
  .footer_header2 {
    padding-top: 20px;
    margin-bottom: -5px;
  }
}

@media (max-width: 340px) {
  .footer_header {
    font-size: 16px;
  }
  .footer_header2 {
    font-size: 16px;
  }
}

.email_pos{
  display: flex;
  align-items: center;
}