.headerContainer {
  margin-top: -50px;
}

.abtheadline,
.abtsecondhead,
.abtblkhdr,
.abtblkprg {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  flex-wrap: wrap;
  font-family: "Almarai";
  font-style: normal;
  color: #1b1b43;
}

.abtrow {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-top: -45px;
}
.abtheadline {
  margin-top: 80px;
  font-weight: 600;
  font-size: 40px;
  line-height: 75px;
  letter-spacing: 0em;
  line-height: 74.96px;
}

.abtsecondhead {
  font-weight: 400;
  font-size: 32px;
  line-height: 60px;
  margin-bottom: 50px;
}

.abtblkhdr {
  font-weight: 600;
  font-size: 24px;
  line-height: 45px;
  text-align: center;
  color: #1b1b43;
  margin-bottom: 15px;
}

.abtblkprg {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  width: 275px;
  color: #1b1b43;
  padding: 0 5px;
}

.abtblksparent {
  display: flex;
  background: #f5f5f5;
  flex-direction: column;
  align-items: center;
  height: 525px;
  width: 100%;
  border-radius: 3%;
  margin-bottom: 10px;
}

.abtblkimg {
  margin-top: 35px;
  margin-bottom: 15px;
  padding: 0 40px;
  width: 285px;
}

.abtscblck {
  margin-top: 50px;
}

.custcolpadabt {
  padding: 20px;
  width: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
}

.custabtblckprg {
  width: 280px;
  padding: 0 5px;
}

.abtanim {
  border: 0px hidden #f8f8f8;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.abtanim:hover {
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  box-shadow: 0 22px 43px rgba(0, 0, 0, 0.6);
  cursor: auto;
}

@media (max-width: 1023px) {
  .abtheadline {
    font-size: 20px;
  }

  .abtsecondhead {
    font-size: 15px;
  }

  .custcolpadabt {
    padding: 10px;
  }
}

@media (max-width: 1200px) {
  .abtscblck {
    margin-top: 0px;
  }

  .abtblksparent {
    width: 350px;
    height: auto;
  }
}

@media (max-width: 900px) {
  .headerContainer {
    margin-top: -165px;
  }
  .abtscblck {
    margin-top: 0px;
  }
  .abtblksparent {
    width: 310px;
    height: auto;
  }
}

@media (max-width: 400px) {
  .abtheadline {
    font-size: 14px;
    font-weight: 600;
    margin-top: -70px;
  }
  .abtsecondhead {
    margin-top: -25px;
  }

  .abtblksparent {
    padding: 28px;
    width: 260px;
    height: auto;
    margin: 0;
    align-items: center;
  }

  .abtblkprg {
    width: 250px;
    font-size: 13px;
  }

  .abtblkimg {
    width: 220px;
  }

  .abtblkhdr {
    font-size: 17px;
  }
}

@media (max-width: 280px) {
  .abtheadline {
    font-size: 14px;
    font-weight: 600;
    margin-top: 50px;
  }
  .abtsecondhead {
    margin-top: -25px;
  }

  .abtblksparent {
    padding: 28px;
    width: auto;
    height: auto;
    margin: 0;
    align-items: center;
  }

  .abtblkprg {
    width: 190px;
  }

  .abtblkimg {
    width: 190px;
  }

  .headerContainer {
    margin-top: -225px;
  }
}
