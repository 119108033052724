.Service_header {
  margin-top: 50px;
  font-family: "Almarai";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 90px;
  text-align: center;
  color: #002cff;
}

.border1 {
  background-image: url("../Images/border1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  max-width: 280px;
  max-height: 280px;
}

.border2 {
  background-image: url("../Images/border2.png");
  background-repeat: no-repeat;
  background-size: 100%;
  max-width: 280px;
  max-height: 280px;
}

.border3 {
  background-image: url("../Images/border3.png");
  background-repeat: no-repeat;
  background-size: 100%;
  max-width: 280px;
  max-height: 280px;
}

.border4 {
  background-image: url("../Images/border4.png");
  background-repeat: no-repeat;
  background-size: 100%;
  max-width: 280px;
  max-height: 280px;
}
* {
  box-sizing: border-box;
}

.box {
  width: 280px;
  height: 280px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.name {
  padding: 10px;
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  font-family: "Almarai";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 37px;
  text-align: center;
  color: #1b1b43;
}
.special-name {
  font-size: 15px;
  font-weight: 600;
}

.one0 {
  width: 140px;
  height: 140px;
  border-top: 4px solid #a3a3ff;
  border-left: 4px solid #a3a3ff;
  border-top-left-radius: 20px;
}

.two0 {
  width: 140px;
  height: 140px;
  border-top: 4px solid #a3a3ff;
  border-right: 4px solid #a3a3ff;
  border-top-right-radius: 20px;
}

.three0 {
  width: 140px;
  height: 140px;
  border-bottom: 4px dotted #a3a3ff;
  border-left: 4px dotted #a3a3ff;
  border-bottom-left-radius: 20px;
}

.four0 {
  width: 140px;
  height: 140px;
  border-bottom: 4px solid #a3a3ff;
  border-right: 4px solid #a3a3ff;
  border-bottom-right-radius: 20px;
}

/* 888888888888 */
.one1 {
  width: 140px;
  height: 140px;
  border-top: 4px dotted #002cff;
  border-left: 4px dotted #002cff;
  border-top-left-radius: 20px;
}

.two1 {
  width: 140px;
  height: 140px;
  border-top: 4px solid #002cff;
  border-right: 4px solid #002cff;
  border-top-right-radius: 20px;
}

.three1 {
  width: 140px;
  height: 140px;
  border-bottom: 4px solid #002cff;
  border-left: 4px solid #002cff;
  border-bottom-left-radius: 20px;
}

.four1 {
  width: 140px;
  height: 140px;
  border-bottom: 4px solid #002cff;
  border-right: 4px solid #002cff;
  border-bottom-right-radius: 20px;
}

.one2 {
  width: 140px;
  height: 140px;
  border-top: 4px solid #a3a3ff;
  border-left: 4px solid #a3a3ff;
  border-top-left-radius: 20px;
}

.two2 {
  width: 140px;
  height: 140px;
  border-top: 4px dotted #a3a3ff;
  border-right: 4px dotted #a3a3ff;
  border-top-right-radius: 20px;
}

.three2 {
  width: 140px;
  height: 140px;
  border-bottom: 4px solid #a3a3ff;
  border-left: 4px solid #a3a3ff;
  border-bottom-left-radius: 20px;
}

.four2 {
  width: 140px;
  height: 140px;
  border-bottom: 4px solid #a3a3ff;
  border-right: 4px solid #a3a3ff;
  border-bottom-right-radius: 20px;
}

/* 8888888888 */
.one3 {
  width: 140px;
  height: 140px;
  border-top: 4px solid #002cff;
  border-left: 4px solid #002cff;
  border-top-left-radius: 20px;
}
.two3 {
  width: 140px;
  height: 140px;
  border-top: 4px solid #002cff;
  border-right: 4px solid #002cff;
  border-top-right-radius: 20px;
}
.three3 {
  width: 140px;
  height: 140px;
  border-bottom: 4px solid #002cff;
  border-left: 4px solid #002cff;
  border-bottom-left-radius: 20px;
}
.four3 {
  width: 140px;
  height: 140px;
  border-bottom: 4px dotted #002cff;
  border-right: 4px dotted #002cff;
  border-bottom-right-radius: 20px;
}
.test {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

@media (max-width: 303px) {
  .box {
    width: 200px;
    height: 200px;
  }
  .special-name {
    font-size: 10px;
    font-weight: 600;
  }

  .one1,
  .one2,
  .one3,
  .two1,
  .two2,
  .two3,
  .three1,
  .three2,
  .three3,
  .four1,
  .four2,
  .four3 {
    width: 100px;
    height: 100px;
  }

  .Service_header {
    margin-top: 50px;
    font-weight: 700;
    font-size: 33px;
    line-height: 70px;
  }

  .one0,
  .two0,
  .three0,
  .four0 {
    width: 100px;
    height: 100px;
  }
}

/*sec*/

@media (max-width: 414px) and (min-width: 303px) {
  .box {
    width: 200px;
    height: 200px;
  }
  .special-name {
    font-size: 10px !important;
    font-weight: 600;
  }

  .name {
    font-size: 15px;
  }
  
  .Service_header {
    margin-top: 50px;
    font-weight: 700;
    font-size: 33px;
    line-height: 40px;
  }

  .one0,
  .two0,
  .three0,
  .four0 {
    width: 98px;
    height: 102px;
  }
  .one1,
  .two1,
  .three1,
  .four1 {
    width: 98px;
    height: 102px;
  }
  .one2,
  .two2,
  .three2,
  .four2 {
    width: 98px;
    height: 102px;
  }
  .one3,
  .two3,
  .three3,
  .four3 {
    width: 98px;
    height: 102px;
  }
}
