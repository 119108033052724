.pub-main{
    margin-top: 60px;
}
.pub-title{
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 90px;
    text-align: center;
    color: #002CFF;
    margin-bottom: 55px;
}

.pub-container{
   display: flex;
   flex-direction: column;
   gap: 32px;
   align-items: center;
   margin: 0 100px;
  
}
.pub-card{
    width: 100%;
    background: #f5f5f5;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 50px;
    gap: 50px;
    text-align:right ;
    cursor: pointer;
    transition: ease-in-out 0.2s;
}

.pub-card:hover {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
    box-shadow: 0 22px 43px rgba(0, 0, 0, 0.32);
   
  }

.pub-content2{
    display: flex;
    flex-direction: row-reverse;
    gap: 40px;
}

.pub-content-title{
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 60px;
    color: #1B1B43;
}

.pub-content-paragraph{
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 51px;
    color: #1B1B43;
    margin-top: -10px;
}

.pub-content2{
    padding-top: 16px;
    gap: 100px;
}
.pub-content2-first-p1,.pub-content2-first-p2,
.pub-content2-second-p1,.pub-content2-second-p2{
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    text-align: right;
    color: #1B1B43;
}

.pub-content2-first-p2,.pub-content2-second-p2{
    margin-top: -15px;
    font-weight: 600;
    font-size: 16px;
}

.pub-img1{
    margin-top: -110px;
}

.pub-content2-first-anchor,.pub-content2-second-anchor{
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #1B1B43;
}
.icon-hi{
    font-size: 18px;
}


/*media*/


@media (max-width: 387px) {
    .pub-card{
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;  
        padding: 15px;
        gap: 0;
    }
    .pub-img1{
        margin-top: 0px;
    }

    .pub-content-title{
       text-align: center;
       font-size: 20px;
    }
    
    .pub-content-paragraph{
       text-align: center;
       font-weight: 700;
       font-size: 20px;
    }
   
    .pub-content-paragraph {       
        line-height: 25px;
        font-weight: 700;
        font-size: 13px;
    }
    .pub-content2{
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .pub-content2-first,.pub-content2-second{
        text-align: center;
        font-size: 13px;
    }
    .pub-content2-first-p1,.pub-content2-first-p2,
    .pub-content2-second-p1,.pub-content2-second-p2{
        text-align: center;
        font-size: 13px;
    }

    .pub-title{
        font-weight: 700;
        font-size: 30px;
    }
    .pub-img1 ,.pub-img2{
        height: 90px;
    }

    .pub-container{
        gap: 32px;
        margin: 0 50px;   
     }

}


@media (min-width: 388px) and (max-width: 502px) {
    .pub-card{
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;  
        padding: 25px;
    }
    .pub-img1{
        margin-top: 0px;
    }

    .pub-content-title{
       text-align: center;
       font-size: 25px;
    }
    
    .pub-content-paragraph{
       text-align: center;
       font-weight: 700;
       font-size: 25px;
    }
   
    .pub-content-paragraph {       
        line-height: 25px;
        margin-top: 20px;
        font-weight: 700;
        font-size: 15px;
    }
    .pub-content2{
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .pub-content2-first,.pub-content2-second{
        text-align: center;
        font-size: 15px;
    }
    .pub-content2-first-p1,.pub-content2-first-p2,
    .pub-content2-second-p1,.pub-content2-second-p2{
        text-align: center;
        font-size: 15px;
    }

    .pub-title{
        font-weight: 700;
        font-size: 40px;
    }
    .pub-img1 ,.pub-img2{
        height: 130px;
    }
    .pub-container{
        gap: 32px;
        margin: 0 50px;   
     }

  }



@media (min-width: 503px) and (max-width: 611px) {
    .pub-card{
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;  
    }
    .pub-img1{
        margin-top: 0px;
    }

    .pub-content-title{
       text-align: center;
    }
    
    .pub-content-paragraph{
       text-align: center;
    }
    .pub-content2 {
        gap: 70px;
    }
    .pub-content-paragraph {       
        line-height: 25px;
        margin-top: 20px;
    }
    .pub-content2{
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .pub-content2-first,.pub-content2-second{
        text-align: center;
    }
    .pub-content2-first-p1,.pub-content2-first-p2,
    .pub-content2-second-p1,.pub-content2-second-p2{
        text-align: center;
    }
    .pub-container{
        gap: 32px;
        margin: 0 50px;   
     }

  }
  
  @media (min-width: 612px) and (max-width: 768px) {
    .pub-card{
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;  
    }
    .pub-img1{
        margin-top: 0px;
    }

    .pub-content-title{
       text-align: center;
    }
    
    .pub-content-paragraph{
       text-align: center;
    }
    .pub-content2 {
        gap: 70px;
    }
    .pub-content-paragraph {       
        line-height: 25px;
        margin-top: 20px;
    }
    .pub-container{
        gap: 32px;
        margin: 0 50px;   
     }
  }
  
  /* @media (min-width: 481px) and (max-width: 600px) {
   
  } */
  
  @media (min-width: 769px) and (max-width: 970px) {
    .pub-card{
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;  
    }
    .pub-img1{
        margin-top: 0px;
    }

    .pub-content-title{
       text-align: center;
    }
    
    .pub-content-paragraph{
       text-align: center;
    }
    .pub-content2 {
        gap: 70px;
    }
    .pub-content-paragraph {       
        line-height: 25px;
        margin-top: 20px;
    }
} 
  
 