.Major_header {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 800;
  font-size: 45px;
  line-height: 110px;
  text-align: center;
  justify-content: center;
  color: #002cff;
  margin-left: -2.5%;
  margin-top: 60px;
}

.sub_header {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  margin-left: -2.5%;
  color: #1b1b43;
}

.Card {
  height: 390px;
  background: rgba(245, 245, 245, 0.9);
  border-radius: 3%;
}

.card_title {
  justify-content: center;
  display: flex;
}

.title {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  color: #1b1b43;
}

.card_text {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 600;
  line-height: initial;
  text-align: center;
  color: #263238;
  font-size: 15px;
  line-height: 22px;
}
.svg-img {
  width: 90px;
}
.special-svg {
  padding-top: 23px;
}

.render {
  border: 0px hidden #f8f8f8;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.render:hover {
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  box-shadow: 0 22px 43px rgba(0, 0, 0, 0.32);
  cursor: pointer;
  border-radius: 5px;
}

.siz {
  max-width: 362px;
  max-height: 375px;
}
.p-55 {
  padding: 0 3rem 0 3rem;
}
.card_body {
  margin: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 245px) and (max-width: 767px) {
  .card_text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-top: 30px;
    text-align: center;
  }

  .card_body {
    margin: 0px;
  }
  .fitt{
    height: fit-content !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .p-55 {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
  .card_body {
    margin: 0px;
  }
}

@media (max-width: 500px) {
  .Major_header {
    font-weight: 800;
    font-size: 32px;
    line-height: 55px;
  }

  .sub_header {
    font-weight: 600;
    font-size: 22px;
  }
  .fitt{
    height: fit-content !important;
  }
}

@media (max-width: 300px) {
  .fitt{
    height: fit-content !important;
  }
}

@media (max-width: 767px) and (min-width: 401px) {
  .fitt{
    height: fit-content !important;
  }
}

@media (min-width:993px) and (max-width:1279px){
    .Card{
      height: 410px !important;
    }
}

@media (max-width:767px){
  ._order1{
    order: 0;
  }
  
  ._order2{
    order: 1;
  }
  
  ._order3{
    order: 2;
  }
  
  ._order4{
    order: 3;
  }
  
  ._order5{
    order: 4;
  }
  ._order6{
    order: 5;
  }
}
